<template>
  <div
    id="controller"
    :class="
      `header-fixed header-mobile-fixed ${
        $route.meta.sidebar ? 'sidebar-enabled' : ''
      }`
    "
  >
    <div class="d-flex flex-column flex-root">
      <div class="d-flex flex-row flex-column-fluid page">
        <Menu />
        <router-view />
        <div
          v-if="$route.meta.sidebar"
          class="sidebar sidebar-right d-flex flex-row-auto flex-column"
        />
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Menu from "@/components/admin/Menu.component";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Admin",
  components: {
    Menu
  },
  data() {
    return {
      stakeholderId: localStorage.getItem("companyId")
    };
  },
  methods: {
    ...mapActions("Admin", ["loadCompanySelected", "loadAdminCompanies"])
  },
  computed: {
    ...mapGetters("Constants", ["isConstantsActive"]),
    ...mapGetters("Admin", [
      "companies",
      "companySelected",
      "isCompanySelectedLoaded",
      "areAdminCompaniesLoaded"
    ])
  },
  watch: {
    isConstantsActive(value) {
      if (value === true && !this.areAdminCompaniesLoaded) {
        this.loadAdminCompanies();
      }
    },
    isCompanySelectedLoaded(value) {
      if (
        localStorage.getItem("managedCompanyId") &&
        value === true &&
        this.$route.name != "Company"
      ) {
        this.$router.push({ name: "Company" });
      }
    },
    areAdminCompaniesLoaded(value) {
      if (value === true) {
        if (!this.companySelected && this.companies.length > 1) {
          this.$router.push({ name: "AdminCompaniesManagement" });
        }
        if (!this.companySelected && this.companies.length == 1) {
          localStorage.setItem(
            "managedCompanyId",
            this.companies[0].stakeholderId
          );
          localStorage.setItem(
            "companySelected",
            JSON.stringify(this.companies[0])
          );
          store.dispatch("Admin/setCompany", this.companies[0]);
          if (this.$route.name != "Company") {
            this.$router.push({ name: "Company" });
          }
        }
      }
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.areAdminCompaniesLoaded) {
      this.loadAdminCompanies();
    }

    if (this.areAdminCompaniesLoaded && this.companies.length > 1) {
      this.$router.push({ name: "AdminCompaniesManagement" });
    }

    if (this.areAdminCompaniesLoaded && this.companies.length == 1) {
      localStorage.setItem("managedCompanyId", this.companies[0].stakeholderId);
      localStorage.setItem(
        "companySelected",
        JSON.stringify(this.companies[0])
      );
      store.dispatch("Admin/setCompany", this.companies[0]);
      if (this.$route.name != "Company") {
        this.$router.push({ name: "Company" });
      }
    }
  }
};
</script>

<style lang="scss" src="@/assets/scss/controller/style.scss"></style>
<style src="@/assets/scss/login.scss" lang="scss" scoped></style>

<style lang="scss">
.footer {
  margin-top: auto;
  width: 100%;
  display: table-row;
}
body {
  background-color: #fff !important;
}
</style>
